* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 90%;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  /* width: fit-content; */
}

body::-webkit-scrollbar {
  width: 0.6rem;
  border-radius: 0.5rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: darkgrey;
  /* outline: 1px solid slategrey; */
}

.MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 0.7rem !important;
}

/* .MuiTableCell-root.MuiTableCell-head {
  padding: none !important;
} */

.rdrCalendarWrapper {
  background: transparent !important;
}

.rdrDayPassive .rdrDayNumber span {
  color: #9e9e9e !important ;
}

.rdrDayNumber span {
  color: #fff !important ;
}

.rdrMonthAndYearPickers select {
  color: #fff !important;
}

.rdrMonthAndYearPickers select option {
  color: black !important;
  font-family: "Montserrat" !important;
  background-color: #eee !important;
  text-align: left !important;
}

.rdrCalendarWrapper {
  font-family: "Montserrat" !important;
}

.MuiTooltip-tooltip {
  background-color: black !important;
}

.MuiTooltip-arrow {
  color: black !important;
}

.cls-1 {
  fill: #fff;
}
.cls-2 {
  fill: #0f6eff;
  stroke: #fff;
  stroke-miterlimit: 10;
  stroke-width: 0.5px;
}

.chart-tooltip {
  min-width: 3rem !important;
  min-height: 3rem !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.5rem !important;
  background-color: #1c40a8 !important;
  margin: 0 !important;
  border: 0 !important;
  outline: none !important;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chart-tooltip p {
  color: #ffffff99;
  margin: 0;
  padding: 0;
}

.css-1ti6opd-MuiSlider-thumb,
.css-19c0tnq {
  height: 20px !important;
  width: 20px !important;
  background-color: rgba(255, 168, 0, 1) !important;
  border: 2px solid white;
}

.css-3f618k-MuiSlider-root,
.css-141zskv {
  height: 12px !important;
  width: 564px !important;
}

.css-1gv0vcd-MuiSlider-track,
.css-1t2bqnt {
  background: linear-gradient(
    to left,
    rgba(1, 147, 255, 1),
    rgba(1, 104, 255, 1)
  ) !important;
  border: none !important;
}

.css-14pt78w-MuiSlider-rail,
.css-b04pc9 {
  background-color: rgba(39, 63, 130, 1) !important;
}

.css-cijk1b-MuiSwitch-root,
.css-yig856 {
  height: 27px !important;
  width: 58px !important;
  border-radius: 58px !important;
}

.css-cijk1b-MuiSwitch-root .MuiSwitch-thumb {
  height: 23.4px !important;
  width: 23.4px !important;
  border-radius: 50% !important;
}

.css-yig856 .MuiSwitch-thumb {
  height: 23.4px !important;
  width: 23.4px !important;
  border-radius: 50% !important;
}

.css-cijk1b-MuiSwitch-root .MuiSwitch-track,
.css-dnkzfa-MuiSwitch-track {
  background-color: rgba(16, 36, 141, 1) !important;
}

.css-yig856 .MuiSwitch-track,
.css-g5sy4h {
  background-color: rgba(16, 36, 141, 1) !important;
}

.css-cijk1b-MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(30px) !important;
}

.css-yig856 .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(30px) !important;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.8) 1%,
    black 99%
  ) !important;
}

.css-79ws1d-MuiModal-root {
  backdrop-filter: blur(3px) !important;
}

#demo-simple-select > div {
  display: flex;
  align-items: center;
  gap: 1rem;

  > span {
    height: 38px;
    width: 38px;
    border-radius: 13px;
    background-color: rgba(5, 168, 107, 1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > div {
    > h5 {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: rgba(255, 255, 255, 1);
    }
    > p {
      font-size: 11px;
      font-weight: 400;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.4);
    }
  }
}

/* .css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12.5px 14px !important;
} */

.css-ja6opi-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: transparent !important;
  background: linear-gradient(
    to right,
    rgba(0, 37, 143, 1),
    rgba(0, 25, 99, 1)
  ) !important;
}

.signupPhoneContainer {
  margin: 0 0 0.5rem 0;
}

.signupPhoneContainer,
.signupSearch,
.signupSearchOnBoarding {
  width: 100% !important;
}

.signupSearch {
  background-color: transparent !important;
  color: white !important;
  border-color: rgba(255, 255, 255, 0.23) !important;
  border-radius: 9px !important;
  height: 38px !important;
}

.signupSearchOnBoarding {
  background-color: transparent !important;
  color: white !important;
  border-color: rgba(255, 255, 255, 0.23) !important;
  border-radius: 9px !important;
  height: 54px !important;
}

.signupSearch:hover,
.signupSearchOnBoarding:hover {
  border-color: white !important;
}

.signupSearch:focus,
.signupSearchOnBoarding:focus {
  border-color: #90caf9 !important;
}

.signupDropdown {
  color: black !important;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none !important;
}

.react-tel-input .open {
  background-color: transparent !important;
}

.otpInput {
  height: 60px;
  width: 50px !important;
  border-radius: 8px;
  border: 1.5px solid rgba(131, 137, 160, 1);
  background-color: transparent;
  outline: none;
  color: white;
}

.otpInput:active,
.otpInput:focus,
.otpInput:hover {
  border: 1.5px solid rgba(15, 110, 255, 1);
}

.otpInputContainer {
  gap: 1.5rem;
  justify-content: space-between;
}

.grecaptcha-badge { visibility: hidden; }